import Ajax from "Utils/ajax";
export const getStaffList = (data) => Ajax.post('hr/getStaffList',data); 
export const getClaimCategory = (data) => Ajax.post('account/claimCategory',data); 
export const expenseClaimList = (data) => Ajax.post('account/expenseClaimList',data); 
export const travelMode = (data) => Ajax.post('account/travelMode',data); 
export const travelRate = (data) => Ajax.post('account/travelRate',data); 
export const getUserDetailsForExpense = (data) => Ajax.post('account/getUserDetailsForExpense',data); 
export const addUpdateExpense = (data) => Ajax.post('account/addUpdateExpense',data);
export const removeClaim = (data) => Ajax.post('account/removeClaim',data);
export const getClaimById = (data) => Ajax.post('account/getClaimById',data);
export const isSalaryfreeze = (data) => Ajax.post('account/isSalaryfreeze',data);
export const reimbursementAppliedTwiceForMonth = (data) => Ajax.post('account/reimbursementAppliedTwiceForMonth',data);
export const validationforholidayWorkingClaim = (data) => Ajax.post('account/validationforholidayWorkingClaim',data);
export const claimAlreadyApplied = (data) => Ajax.post('account/claimAlreadyApplied',data);
